'use strict'

import mapMarker from '../images/mapMarker.svg'
import variables from '../scss/module/variables.module.scss'

/*document.addEventListener(
    'DOMContentLoaded',
    function () {
        contactsBlockMapInit()
    }
)*/
export default class ContactsBlockMap {
    init() {

        let contactsBlock = document.querySelector('[data-contact-block-map]');

        if(contactsBlock) {
            let latitude = contactsBlock.dataset.latitude;
            let longitude = contactsBlock.dataset.longitude;
            let coordinates = [latitude, longitude];
            let blockId = contactsBlock.getAttribute('id')

            ymaps.ready(function () {
                let contactMap = new ymaps.Map(blockId, {
                    center: coordinates,
                    zoom: 16
                })
                contactMap.behaviors.disable('scrollZoom')

                if(window.outerWidth <= parseFloat(variables.mediaLarge)) {
                    contactMap.behaviors.disable('drag')
                }

                let officePoint = new ymaps.Placemark(coordinates, {}, {
                    iconLayout: 'default#image',
                    iconImageHref: mapMarker,
                    iconImageSize: [38, 49],
                    iconImageOffset: [-19, -49]
                });

                contactMap.geoObjects.add(officePoint);
            })
        }
    }
}


