'use strict'

document.addEventListener(
    'DOMContentLoaded',
    function () {

        new WOW({
            animateClass: 'animate__animated',
        }).init();
    }
)
