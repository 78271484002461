'use strict'

window.addEventListener(
    'scroll',
    function () {
        checkRunningNumbers()
    }
)

function checkRunningNumbers() {
    let runningNumbers = document.querySelectorAll('[data-running-number]')

    runningNumbers.forEach((runningNumber) => {
        let box = runningNumber.getBoundingClientRect()

        if((window.innerHeight - box.top) > box.height && runningNumber.dataset.ranNumber != 1) {
            startRunNumber(runningNumber)
        }
    })
}

function startRunNumber(item) {
    item.dataset.ranNumber = '1'

    let endNum = parseInt(item.dataset.runningNumber)

    for (let i = 0; i <= endNum; i++) {
        setTimeout(function(){
            item.textContent = i
        },  (i*600)/endNum)
    }

}