'use strict'

document.addEventListener(
    'DOMContentLoaded',
    function () {
        calcAccordions()
        addAccordionsListeners()
    }
)

let windowWidth = window.outerWidth

window.addEventListener(
    'resize',
    function () {
        if(window.outerWidth !== windowWidth) {
            windowWidth = window.outerWidth
            calcAccordions()
        }
    }
)

function calcAccordions() {
    let accordions = document.querySelectorAll('[data-accordion]');

    accordions.forEach((accordion) => {
        let accordionContent = accordion.querySelector('[data-accordion-content]')

        if(!accordion.classList.contains('accordion_open')) {
            accordionContent.style.height = 'auto'
            accordion.dataset.accordionContentHeight = accordionContent.offsetHeight
            accordionContent.style.height = '0'
        } else {
            accordionContent.style.height = 'auto'
            accordion.dataset.accordionContentHeight = accordionContent.offsetHeight
            accordionContent.style.height = accordion.dataset.accordionContentHeight + 'px'
        }

    })
}

function addAccordionsListeners() {
    let accordions = document.querySelectorAll('[data-accordion]');

    accordions.forEach((accordion) => {
        accordion.addEventListener('click', function(){
            let accordionContent = this.querySelector('[data-accordion-content]')

            if(this.classList.contains('accordion_open')) {
                accordionContent.style.height = '0'
            } else {
                accordionContent.style.height = accordion.dataset.accordionContentHeight + 'px'
            }

            this.classList.toggle('accordion_open')
        })
    })
}