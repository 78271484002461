"use strict";
//!!TODO: Подумать над переходом к классу
// styles
import './custom/scss/all.scss';

// static images
import './custom/images/logo.svg';
//import '../../custom/img/icon-favorite.svg';
// js
import './custom/js/header_default';
import './custom/js/banner_page-title';
import './custom/js/reviews-slider';
import './custom/js/tariffs';
import './custom/js/accordion';
import ContactsBlockMap from './custom/js/contacts-block';
import './custom/js/up-button';
import './custom/js/scroll-link';
import './custom/js/wow';
import './custom/js/preloader';
import './custom/js/running-numbers';
window.ContactsBlockMap = ContactsBlockMap;
//import '../../custom/vue/favoriteHeader/main';
//import menuHeader from '../../custom/js/menuHeader';
//window.menuHeader = menuHeader;