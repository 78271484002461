'use strict'

//!!TODO: Вынести подсказки в отдельный файл!
document.addEventListener(
    'DOMContentLoaded',
    function () {
        calcTariffsHeight()
        addTariffButtonsListeners()
        addHintListeners()
    }
)

let windowWidth = window.outerWidth

window.addEventListener(
    'resize',
    function () {
        if(window.outerWidth !== windowWidth) {
            windowWidth = window.outerWidth;
            calcTariffsHeight()
        }
    }
)

function setTariffsHeight(item, type = 'crop') {
    let newHeight = 0
    if (type === 'crop') {
        newHeight = item.dataset.cropHeight
    } else {
        newHeight = item.dataset.fullHeight
    }

    item.style.height = newHeight + 'px';
}

function calcTariffsHeight() {
    let lists = document.querySelectorAll('[data-tariffs-list]');

    lists.forEach((list) => {
        let countLines = 0;
        let column = list.querySelector('[data-tariff-column]')
        let listHeight = column.querySelector('[data-tariff-head]').offsetHeight

        if(list.querySelector('[data-tariff-button-line]')) {
            listHeight += list.querySelector('[data-tariff-button-line]').offsetHeight
        }

        let fullHeight = listHeight
        let lines = column.querySelectorAll('[data-tariff-line]')

        if(window.outerWidth >= 1200){
            countLines = list.dataset.countVisibleLines
        } else {
            countLines = list.dataset.countVisibleLinesMobile
        }

        let i = 0
        lines.forEach((line) => {
            if (i < countLines) {
                listHeight += line.offsetHeight
            }

            fullHeight += line.offsetHeight
            i++
        })

        list.dataset.fullHeight = fullHeight
        list.dataset.cropHeight = listHeight
        setTariffsHeight(list)
    })
}

function addTariffButtonsListeners() {
    let buttons = document.querySelectorAll('[data-set-tariffs-height]');
    buttons.forEach((button) => {
        button.addEventListener('click', function (event){
            event.preventDefault()

            let newState = this.dataset.setTariffsHeight;
            let nextStat = newState === 'crop' ? 'full' : 'crop'
            let parentBlock = this.closest('[data-tariffs]')
            let list;
            if(parentBlock) {
                list = parentBlock.querySelector('[data-tariffs-list]')
            } else {
                list = this.closest('[data-tariffs-list]')
            }
            let texts = JSON.parse(this.dataset.text);

            setTariffsHeight(list, newState);

            this.dataset.setTariffsHeight = nextStat
            this.innerText = texts[nextStat]
        })
    })
}

function addHintListeners() {
    let hints = document.querySelectorAll('[data-tariff-hint]');
    hints.forEach((hint) => {
        hint.addEventListener('click', function (event){
            event.preventDefault()

            Fancybox.show([{ src: this.dataset.tariffHint, type: "html" }], {mainClass: 'fancybox_hint'});
        })
    })
}