'use strict'


document.addEventListener(
    'DOMContentLoaded',
    function () {
        addScrollLinksListeners()
    }
)

function addScrollLinksListeners() {
    let links = document.querySelectorAll('[data-scroll-link]');

    links.forEach((link) => {
        link.addEventListener(
            'click',
            function(event) {
                event.preventDefault()

                let href = this.dataset.scrollLink
                let target = document.querySelector('['+href+']')
                let targetOffset = target.offsetTop
                let additionalOffset = 56

                window.scrollTo({
                    top: (targetOffset - additionalOffset),
                    behavior: 'smooth'
                })
            }
        )
    })
}