'use strict'

document.addEventListener(
    'DOMContentLoaded',
    function () {
        setHeaderItemsWidth()

        new WOW().init();
    }
)

function setHeaderItemsWidth() {
    const headerMenu = document.querySelector('[data-header-menu]');

    if (headerMenu !== null) {
        const header = document.querySelector('[data-header]');
        let headerPaddings = parseInt(getComputedStyle(header).paddingLeft) + parseInt(getComputedStyle(header).paddingRight);
        let headerWidth = header.clientWidth - headerPaddings;
        let childrenWidth = 0;
        let logoWidth = 0;
        let phoneWidth = 0;


        for (let child of header.children) {
            if (child.classList.contains('logo')) {
                child.style.width = 'unset';
                logoWidth = child.offsetWidth;
            }

            if (child.classList.contains('phone')) {
                child.style.width = 'unset';
                phoneWidth = child.offsetWidth;
            }
        }

        for (let child of header.children) {
            if (child.classList.contains('logo') && logoWidth < phoneWidth) {
                child.style.width = phoneWidth + 'px';
            }

            if (child.classList.contains('phone') && logoWidth > phoneWidth) {
                child.style.width = logoWidth + 'px';
            }

            if (child !== headerMenu) {
                childrenWidth += child.offsetWidth;
            }
        }


        let menuWidth = Math.floor(headerWidth - childrenWidth);

        headerMenu.style.width = menuWidth - 2 + 'px';
    }
}

window.addEventListener(
    'resize',
    function () {
        setHeaderItemsWidth()
    }
)

window.addEventListener(
    'orientationchange',
    function () {
        setHeaderItemsWidth()
    }
)