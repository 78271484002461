'use strict'

new Swiper('[data-swiper-reviews]', {
    loop: true,
    navigation: {
        nextEl: '.reviews__button_next',
        prevEl: '.reviews__button_prev',
    },
    pagination: {
        el: '.reviews__pagination',
        clickable: true,
    },
    breakpoints: {
        320:{
            slidesPerView: 1,
            spaceBetween: 12
        },
        575: {
            slidesPerView: 1,
            spaceBetween: 12
        },
        767: {
            slidesPerView: 2,
            spaceBetween: 12
        },
        991: {
            slidesPerView: 2,
            spaceBetween: 24
        },
        1199: {
            slidesPerView: 3,
            spaceBetween: 24
        },
    }
});