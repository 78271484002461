'use strict'

document.addEventListener(
    'DOMContentLoaded',
    function () {
        initUpButton()
        checkUpButton()
    }
)

window.addEventListener(
    'scroll',
    function () {
        checkUpButton()
    }
)

function checkUpButton() {
    if((pageYOffset - window.outerHeight) > -150) {
        document.querySelector('[data-up-button]').classList.add('up-button_active')
    } else {
        document.querySelector('[data-up-button]').classList.remove('up-button_active')
    }
}

function initUpButton() {
    document.querySelector('[data-up-button]').addEventListener(
        'click',
        function() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    )
}