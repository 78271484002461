'use strict'

document.addEventListener(
    'DOMContentLoaded',
    function () {
        setBannerHeightValue();
        initArrowAnimation()
    }
)

function setBannerHeightValue() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

/*window.addEventListener(
    'resize',
    function () {
        setBannerHeightValue();
    }
)*/

window.addEventListener(
    'orientationchange',
    function () {
        setTimeout(setBannerHeightValue, 50);
    }
)

function initArrowAnimation() {
    if(document.querySelector('[data-banner-page-title-scroll-arrow]')) {
        document.querySelector('[data-banner-page-title-scroll-arrow]').addEventListener(
            'click',
            function (e) {
                let banner = document.querySelector('[data-banner-page-title]');
                window.scrollTo({top: banner.offsetHeight, behavior: 'smooth'});
            }
        );
    }
}
